<template>
  <div class="roles-modify">
    <v-container class="roles-modify__container" fluid>
      <entity-modify-header class="mb-10" :title="title" />

      <form-builder v-if="!isLoading" :schema="$options.schema" :initial-data="initialData" @submit="submit">
        <template #footer="{ valid }">
          <v-row class="mt-10">
            <v-col>
              <v-btn color="secondary" block class="primary--text" :to="backRoute">{{ $t('button.back') }}</v-btn>
            </v-col>
            <v-col>
              <v-btn type="submit" color="primary" block :disabled="!valid" :loading="isUpload">
                {{ submitButtonText }}
              </v-btn>
            </v-col>
          </v-row>
        </template>
      </form-builder>
    </v-container>
  </div>
</template>

<script>
// Services
import usersService from '@/services/users';
import analyticsService from '@/services/analytics';

// Components
import EntityModifyHeader from '@/components/EntityModifyHeader.vue';
import FormBuilder from '@/components/schema/FormBuilder.vue';

// Models
import { schema } from '@/schemas/role.schema';
import { createModelData } from '@/schemas/createModelData';
import notificationService from '@/services/notification';

// Utils
import { getCredentialsFromSections } from '@/utils/roles';

// Constants
import { ROLES, ROLES_DETAILED } from '@/constants/routes';
import { CREATE_ROLE, EDIT_ROLE } from '@/constants/analyticsActions';

export default {
  name: 'RolesModify',

  components: { EntityModifyHeader, FormBuilder },

  props: {
    isEdit: { type: Boolean, default: false },
    id: { type: Number, default: null },
    prevPage: { type: Number, default: 1 },
  },

  data() {
    return {
      isLoading: false,
      initialData: createModelData(schema),
      isUpload: false,
    };
  },

  computed: {
    submitButtonText() {
      return this.isEdit ? this.$t('button.edit_role') : this.$t('button.add_role');
    },

    backRoute() {
      if (this.isEdit) {
        if (this.$route.params.fromDetailed) {
          return { name: ROLES_DETAILED, params: { id: this.id, prevPage: this.$route.params.prevPage } };
        }
        return { name: ROLES, query: { page: this.$route.params.prevPage || 1 } };
      }

      return { name: ROLES, query: { page: this.$route.params.prevPage || 1 } };
    },

    title() {
      return this.isEdit ? this.$t('role.edit_role') : this.$t('role.add_role');
    },
  },

  mounted() {
    if (this.isEdit) {
      this.isLoading = true;
      usersService
        .getRoleById(this.id)
        .then(role => {
          const newRole = { ...role };
          newRole.accessLevels = this.getCredentialsFromSections(newRole);
          this.initialData = newRole;
        })

        .finally(() => {
          this.isLoading = false;
        });
    }
  },

  methods: {
    getCredentialsFromSections,
    async submit(data) {
      if (this.isUpload) {
        return;
      }
      this.isUpload = true;
      const body = { ...data };
      const accessLevels = this.formatDataSection(body);
      body.accessLevels = accessLevels;

      const saveRole = this.isEdit ? usersService.updateRoleById : usersService.createRole;
      try {
        const role = await saveRole({ ...body, roleId: this.id });
        analyticsService.track(this.isEdit ? EDIT_ROLE : CREATE_ROLE);
        notificationService.success(this.isEdit ? this.$t('role.edited') : this.$t('role.created'), 2000);
        this.$router.push({ name: ROLES_DETAILED, params: { id: role.id || this.id } });
      } catch (error) {
        this.initialData = { ...data };

        const keys = Object.keys(error?.response?.data);

        if (keys.length) {
          for (let i = 0; i < keys.length; i += 1) {
            const key = keys[i];
            const errorText = this.getErrorText(error.response.data, key);
            this.$options.notificationItem = notificationService.error(errorText);
          }
        }
      } finally {
        this.isUpload = false;
      }
    },

    formatDataSection(data) {
      const accessLevels = [];
      Object.keys(data.accessLevels).forEach(key => {
        if (data.accessLevels[key].length) {
          data.accessLevels[key].forEach(value => {
            accessLevels.push({ name: key, type: value });
          });
        }
      });
      return accessLevels;
    },

    getErrorText(data, key) {
      return Array.isArray(data[key]) ? data[key][0] : data[key];
    },
  },

  schema,
  notificationItem: null,
};
</script>

<style lang="scss">
.roles-modify {
  &__container {
    max-width: 548px !important;
  }
}
</style>
